import useAuth from '../../core/hooks/useAuth';
import { Navigate, Outlet } from 'react-router-dom';
import { userHasPermission } from '../../core/utils/helpers';

import AppLoader from '../AppLoader/AppLoader';
import Page403 from '../../pages/403/Page403';

const ProtectedRoute = ({permission, children}) => {

  const { dispatcherToken, extractorToken, authenticatedUser, authenticatedUserLoading, decisionToken } = useAuth();
  if (!dispatcherToken || !extractorToken || !decisionToken) return <Navigate to="/login" replace />;
  if (permission && authenticatedUserLoading)
    return <AppLoader />;
  if(permission && !userHasPermission(authenticatedUser, permission)) return <Page403 />;
  return children ? children : <Outlet />;
};

export default ProtectedRoute;