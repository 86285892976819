export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT,
    authority:
      "https://login.microsoftonline.com/" + process.env.REACT_APP_AZURE_TENANT,
    redirectUri: process.env.REACT_APP_CONFIG_REDIRECT,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
    secureCookies: true,
  },
};

export const loginRequest = {
  scopes: [
    process.env.REACT_APP_AZURE_CLIENT + "/.default",
    //"User.Read",
  ],
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
