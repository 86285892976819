import React from 'react';
import { IconSpinner } from '../_Catalog/icons';

const PrimaryButton = ({ buttonEvent, iconButton, buttonText, isLoading, type, disabled }) => {
  return (
    <button
      type={type}
      className={`${disabled ? 'cursor-not-allowed bg-gray-400' : 'bg-primary-color'} inline-flex items-center justify-center rounded-md border border-transparent 
      px-3 py-1.5 text-sm font-medium text-app-white-color dark:text-primary-text-color hover:shadow focus:outline-none sm:w-auto ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
      onClick={buttonEvent}
      disabled={isLoading || disabled}
    >
      {isLoading ? (
        <div className="animate-spin h-5 w-5 mr-2">
          <IconSpinner />
        </div>
      ) : (
        <div className='mr-2'>
        {iconButton}
          </div>
      )} {buttonText}
    </button>
  );
};

export default PrimaryButton;
