import { useEffect, useState } from "react";
import useAuth from "../../../core/hooks/useAuth";
import loginService from "./loginService";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import loginSchema from "./loginValidationSchema";
import { toast } from "react-toastify";

const useLogin = () => {
  const {
    dispatcherToken,
    extractorToken,
    decisionToken,
    sapientToken,
    setDispatcherToken,
    setExtractorToken,
    setDecisionToken,
    setSapientToken,
    isMicrosoftAzureAuthLoading,
    handleMicrosoftAzureLogin,
    microsoftAzureSignIn,
    setLocalSession,
    setUserToken,
    setRefreshToken,
  } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { isSubmitted, errors },
  } = useForm({ resolver: yupResolver(loginSchema) });

  const [loginLoading, setLoginLoading] = useState(false);

  const signIn = async (data) => {
    setLoginLoading(true);
    try {
      const response = await loginService.signIn(data);
      if (response.data) {
        const {
          accessTokenDipsatcher,
          accessTokenExtractor,
          accessTokenDecision,
          accessTokenSapient,
          accessToken,
          refreshToken,
        } = response.data;
        setDispatcherToken(accessTokenDipsatcher);
        setExtractorToken(accessTokenExtractor);
        setDecisionToken(accessTokenDecision);
        setSapientToken(accessTokenSapient);
        setLocalSession(true)
        setUserToken(accessToken);
        setRefreshToken(refreshToken);
      }
    } catch (error) {
      console.log(error)
      console.error("API LOGIN ERROR:", error);
      handleLoginError(error);
    } finally {
      setLoginLoading(false);
    }
  };

  const handleLoginError = (error) => {
    if (error.response?.data) {
      const errorCode = error.response.data.code;
      switch (errorCode) {
        case 6009:
        case 6010:
          toast.error("Access Denied !");
          break;
        case 6004:
          toast.error("Wrong Username / Password !");
          break;
        case 6003:
          toast.error("Access Denied !");
          break;
        default:
          toast.error("Access Denied !");
          break;
      }
    } else {
      toast.error("An error has occurred !");
    }
  };

  const submitLogin = (loginData) => signIn(loginData);



  useEffect(() => {
    microsoftAzureSignIn();
  }, [microsoftAzureSignIn]);

 
  
  return {
    loginLoading,
    dispatcherToken,
    extractorToken,
    decisionToken,
    sapientToken,
    submitLogin,
    register,
    handleSubmit,
    isSubmitted,
    errors,
    isMicrosoftAzureAuthLoading,
    handleMicrosoftAzureLogin,
  };
};

export default useLogin;
