import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import Navigation from '../AppSide/components/Navigation';
import { useService } from "../../components/ServiceMenu/ServiceContext"
import AppHeader from '../AppHeader/AppHeader';
import { useAppGlobal } from '../../core/contexts/AppGlobalProvider';
const AppLayout = ({ children }) => {
  const { theme } = useAppGlobal();
  const { selectedService } = useService();
  return (
    
    <div className='flex'>
      {(selectedService !=='/dashboard' && selectedService !=='/') && (
           <Navigation />
      )}


      <div className={`app-layout h-screen w-full overflow-hidden`}>
      {(selectedService ==='/dashboard') && (
     <AppHeader />
      )}
        <ToastContainer
          autoClose={2000}
          hideProgressBar={true}
          theme={theme === 'dark' ? 'dark' : 'light'}
          className="mt-8"
        />
        {children}
      </div>
      {/* <AppFooter /> */}
    </div>
  );
};

export default AppLayout;