import { useCallback, useContext, useEffect, useState } from "react";
import { AppAuthContext } from "../contexts/AppAuthProvider";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { EventType, InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "../utils/apis/httpMsal";
import { microsoftAzureSignInService } from "../services/authSSOService";
import { tokensGenerator } from "../utils/helpers";
const useAuth = () => {
  const {
    userToken,
    setUserToken,
    setRefreshToken,
    authenticatedUser,
    setAuthenticatedUser,
    dispatcherToken,
    extractorToken,
    decisionToken,
    sapientToken,
    setDispatcherToken,
    setExtractorToken,
    setDecisionToken,
    setSapientToken,
    setLocalSession,
  } = useContext(AppAuthContext);
  const {
    instance: microsoftAzureInstance,
    accounts: microsoftAzureAccounts,
    inProgress: microsoftAzureInProgress,
  } = useMsal();
  const navigate = useNavigate();
  const isMicrosoftAzureAuthenticated = useIsAuthenticated();

  const [isMicrosoftAzureAuthLoading, setIsMicrosoftAzureAuthLoading] =
    useState(false);
  const [isMicrosoftAzureAuthPrompted, setIsMicrosoftAzureAuthPrompted] =
    useState(false);
  const [authenticatedUserLoading, setAuthenticatedUserLoading] =
    useState(true);
  const [authError, setAuthError] = useState("");

  const handleMicrosoftAzureLogin = () => {
    setIsMicrosoftAzureAuthLoading(true);
    try {
      microsoftAzureInstance.loginPopup(loginRequest);
    } catch (e) {
      console.error("AVX MSAL ERROR", e);
    }
  };
  const handleMicrosoftAzureLogout = async () => {
    try {
      if (microsoftAzureAccounts.length > 0) {
        await microsoftAzureInstance.logout({
          postLogoutRedirectUri: "/logout",
        });
      } else {
        navigate("/logout", { replace: true });
      }
    } catch (e) {
      console.error("Msal logout error", e);
    }
  };
  useEffect(() => {
    const callbackId = microsoftAzureInstance.addEventCallback(
      ({ eventType }) => {
        const eventsLoadArray = [
          EventType.LOGIN_SUCCESS,
          EventType.ACQUIRE_TOKEN_SUCCESS,
          EventType.ACQUIRE_TOKEN_START,
        ];
        if (eventsLoadArray.includes(eventType)) {
          setIsMicrosoftAzureAuthLoading(true);
          setIsMicrosoftAzureAuthPrompted(true);
        }
      }
    );

    return () => {
      if (callbackId) microsoftAzureInstance.removeEventCallback(callbackId);
    };
  }, []); // eslint-disable-line

  const microsoftAzureSignIn = useCallback(async () => {
    if (
      (isMicrosoftAzureAuthPrompted &&
        isMicrosoftAzureAuthenticated &&
        microsoftAzureInProgress === InteractionStatus.None &&
        (!userToken || !authenticatedUser)) ||
      microsoftAzureAccounts.length > 0
    ) {
      setIsMicrosoftAzureAuthLoading(true);
      try {
        const silentTokenResponse =
          await microsoftAzureInstance.acquireTokenSilent({
            ...loginRequest,
            account: microsoftAzureAccounts[0],
          });
        const { data } = await microsoftAzureSignInService(
          silentTokenResponse.accessToken
        );
        const {
          accessTokenDipsatcher,
          accessTokenExtractor,
          accessTokenDecision,
          accessTokenSapient,
        } = await tokensGenerator();
        const { accessToken, refreshToken } = data;
        if (
          !accessTokenDipsatcher ||
          !accessTokenExtractor ||
          !accessTokenDecision ||
          !accessTokenSapient
        )
          throw new Error();
        if (!data) throw new Error();
        setDispatcherToken(accessTokenDipsatcher);
        setExtractorToken(accessTokenExtractor);
        setDecisionToken(accessTokenDecision);
        setSapientToken(accessTokenSapient);
        setUserToken(accessToken);
        setRefreshToken(refreshToken);
        setAuthenticatedUser(microsoftAzureAccounts[0]);
      } catch (error) {
        if (error.response?.data) {
          const errorCode = error.response.data.code;
          switch (errorCode) {
            case 6009:
            case 6010:
            case 6004:
              setAuthError("Access Denied !");
              break;
            case 6003:
              setAuthError("Suspended Account !");
              break;
            default:
              setAuthError("An error has occurred !");
          }
        } else setAuthError("An error has occurred !");
      } finally {
        setIsMicrosoftAzureAuthLoading(false);
        setIsMicrosoftAzureAuthPrompted(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isMicrosoftAzureAuthPrompted,
    microsoftAzureInstance,
    microsoftAzureInProgress,
    isMicrosoftAzureAuthenticated,
    microsoftAzureAccounts,
    userToken,
    authenticatedUser,
    navigate,
    setUserToken,
    setRefreshToken,
  ]);

  return {
    dispatcherToken,
    extractorToken,
    authenticatedUser,
    decisionToken,
    sapientToken,
    setDispatcherToken,
    setExtractorToken,
    setDecisionToken,
    setSapientToken,
    setUserToken,
    setRefreshToken,
    handleMicrosoftAzureLogin,
    isMicrosoftAzureAuthLoading,
    isMicrosoftAzureAuthPrompted,
    setIsMicrosoftAzureAuthPrompted,
    microsoftAzureSignIn,
    authenticatedUserLoading,
    setAuthenticatedUserLoading,
    setLocalSession,
    handleMicrosoftAzureLogout,
    setAuthenticatedUser,
    authError,
    userToken,
    microsoftAzureInstance,
    microsoftAzureAccounts,
  };
};

export default useAuth;
