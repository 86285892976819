import { Link } from 'react-router-dom';
import {
  MageDotsMenu,
} from '../../components/_Catalog/icons';
import { useService } from '../ServiceMenu/ServiceContext';
import logo from "../../assets/img/minotaur-logo.svg";
const AppHeader = () => {
  const { showMenu, setShowMenu } = useService();
  const handleServiceClick = () => {
    setShowMenu(!showMenu);
  };

  return (
    <header className={`relative z-50 flex items-center justify-between px-3 h-10 transition-all duration-300`}>
      <div className="flex items-center gap-x-4 pt-3">
        <Link
          to="/"
          className="flex items-center rounded cursor-pointer stroke-[0.75] hover:stroke-neutral-100 stroke-neutral-400 text-neutral-400  gap-2 transition-colors duration-100"
        >
          <img className="stroke-inherit stroke-[0.75] min-w-[40px] w-[40px]" src={logo} alt="Minotaur" />
          <p className={`text-inherit overflow-clip whitespace-nowrap tracking-wide transition-opacity duration-300`}>
            <span className="text-2xl font-medium text-blue-700">
              MINO<span className="text-orange-600">TAUR</span>
            </span>
          </p>
        </Link>
      </div>

      <div className="ml-auto flex items-center pt-3">

        <button
          onClick={handleServiceClick}
          className={`flex flex-col relative z-10 transition-all duration-200 `}
        >
          <MageDotsMenu color="var(--primary-color)" />
        </button>

      </div>
    </header>
  );
};

export default AppHeader;
