import React, { useState, useEffect } from 'react';
import logo from "../../assets/img/minotaur-logo.svg";

const AppLoader = ({ minimumLoadTime = 2000 }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [startTime, setStartTime] = useState(Date.now());

  useEffect(() => {
    const currentTime = Date.now();
    const elapsedTime = currentTime - startTime;

    if (elapsedTime >= minimumLoadTime) {
      setIsVisible(false);
    } else {
      const remainingTime = minimumLoadTime - elapsedTime;
      const timer = setTimeout(() => setIsVisible(false), remainingTime);

      return () => clearTimeout(timer);
    }
  }, [startTime, minimumLoadTime]);

  // Set the start time when the component mounts
  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  return (
    isVisible && (
      <div className="h-full w-full inset-0 bg-transparent bg-opacity-90 z-50 flex items-center justify-center">
          <img src={logo} className='animate-bounce w-40 h-auto' alt='minotaur application loader' />
      </div>
    )
  );
};

export default AppLoader;
