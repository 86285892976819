import { Link } from 'react-router-dom';
import PageLayout from '../../components/PageLayout/PageLayout';

import './Page403.css';

const Page403 = () => {
  return <PageLayout pageName="Unauthorized" pageClass="page403" >
    <p>You're not authorized to access this page!</p>
    <Link to="/">Go home</Link>
  </PageLayout>
};

export default Page403;