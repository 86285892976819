import { useContext, useEffect, useState } from "react";
import useAuth from "../../../core/hooks/useAuth";
import { AppAuthContext } from "../../../core/contexts/AppAuthProvider";
const useAppSide = () => {
  const {
    dispatcherToken,
    extractorToken,
    decisionToken,
    authenticatedUser,
    authenticatedUserLoading,
    handleMicrosoftAzureLogout,
    userToken,
  } = useAuth();
  const appAuthContext = useContext(AppAuthContext);
const { getUserData } = appAuthContext;
  const [isModalOpen, setModalOpen] = useState(false);

  const getInitialSelectedTimeZone = () => {
    return localStorage.getItem("selectedTimeZone") || "";
  };
  const [selectedTimeZone, setSelectedTimeZone] = useState(
    getInitialSelectedTimeZone
  );
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  const handleOpenUserMenu = () => {
    setIsUserMenuOpen(true);
  };

  const handleCloseUserMenu = () => {
    setIsUserMenuOpen(false);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleTimeZoneSelect = (timeZone) => {
    setSelectedTimeZone(timeZone);
    localStorage.setItem("selectedTimeZone", timeZone);
  };
  useEffect(() => {
   userToken && getUserData();
  },[userToken, getUserData]);


  return {
    dispatcherToken,
    extractorToken,
    decisionToken,
    authenticatedUser,
    authenticatedUserLoading,
    isModalOpen,
    handleOpenModal,
    handleCloseModal,
    selectedTimeZone,
    handleTimeZoneSelect,
    getInitialSelectedTimeZone,
    isUserMenuOpen,
    handleOpenUserMenu,
    handleCloseUserMenu,
    handleMicrosoftAzureLogout,
  };
};

export default useAppSide;