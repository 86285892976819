import httpCore from "../utils/apis/httpCore";

;
const getUserData = async () => {
  const response = await httpCore({
    method: "GET",
    url: "/users/profile",
  });
  return response;
};
const authService = {
  getUserData
  
};

export default authService;