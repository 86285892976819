import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "./useAuth";
const UseLogout = () => {
  const navigate = useNavigate();
  const {
    setDispatcherToken,
    setExtractorToken,
    setDecisionToken,
    setSapientToken,
    setAuthenticatedUser,
    setRefreshToken,
    setUserToken,
    setLocalSession,
    microsoftAzureAccounts,
    microsoftAzureInstance,
  } = useAuth();
 
  const handleMicrosoftAzureLogout = async () => {
  try {
    if (microsoftAzureAccounts.length > 0) {

      await microsoftAzureInstance.logout();
    }
  } catch (e) {
    console.error("Msal logout error", e);
  }
};
  useEffect(() => {
    handleMicrosoftAzureLogout();
    setDispatcherToken();
    setExtractorToken();
    setDecisionToken();
    setSapientToken();
    setAuthenticatedUser();
    setRefreshToken();
    setUserToken();
    setLocalSession();
    handleMicrosoftAzureLogout();
    document.body.classList.remove("dark");
    navigate("/login", { replace: true });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    navigate,
    setDispatcherToken,
    setExtractorToken,
    setDecisionToken,
    setSapientToken,
    setAuthenticatedUser,
    setRefreshToken,
    setUserToken,
    setLocalSession,
    
  ]);

  return null;
};

export default UseLogout;