import React, { createContext, useContext, useState, useEffect } from 'react';
import useMqtt from '../hooks/useMqtt';

const AppGlobalContext = createContext();

export const useAppGlobal = () => useContext(AppGlobalContext);

export const AppGlobalProvider = ({ children }) => {
  // Check for stored dark mode preference in local storage
  const isDarkMode = localStorage.getItem('dark_mode') ? JSON.parse(localStorage.getItem('dark_mode')) : false;
  const [theme, setTheme] = useState(isDarkMode ? 'dark' : 'light');

  // Update body class on mount and theme change
  useEffect(() => {
    if (theme === 'dark') {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [theme]);

  // Toggle dark mode
  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('dark_mode', JSON.stringify(newTheme === 'dark'));
  };

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);

  // System selection state
  const [selectedSystem, setSelectedSystem] = useState('SAP');

  // Mqtt hook
  const { brokerSubscribe, brokerClient, brokerPublish } = useMqtt();

  return (
    <AppGlobalContext.Provider value={{
      currentPage, setCurrentPage, selectedSystem, setSelectedSystem, theme,
      toggleTheme, brokerSubscribe, brokerClient, brokerPublish
    }}>
      {children}
    </AppGlobalContext.Provider>
  );
};