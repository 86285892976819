import httpCore from "../utils/apis/httpCore";

export const microsoftAzureSignInService = async (token) => {
  const response = await httpCore({
    method: "POST",
    url: "/public/sso/az-signin",
    headers: {
      "sso-az-auth": "Bearer " + token,
    },
  });

  return response;
};