import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ServiceContext = createContext();

export const ServiceProvider = ({ children }) => {
  const [selectedService, setSelectedService] = useState("/");
  const [service, setService] = useState("Home");
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setSelectedService(location.pathname);
    if (['/operation-flow',
       '/cortexFlow',
       '/cortex/reporting',
       '/cortex/deployment',
      ].some(path => location.pathname.includes(path))) {
      setService('CortexFlow');
    }
    else if(['/tasks',
      '/connections',
      '/reporting',
      '/dailyReport',
      '/settings/general',
      '/settings/sapient',
     ].some(path => location.pathname.includes(path))) {
     setService('HyperAutomation');
   }
      else if (['/dashboard',
        '/tickets',
        '/reporting',
        '/settings/notif/groupmanagement',
        '/settings/notif/Alert',
        '/settings/notif/jira',
        '/incident',
       ].some(path => location.pathname.includes(path))) {
       setService('Incident Hub');
     }
  }, [location]);

  return (
    <ServiceContext.Provider value={{ selectedService, showMenu, setShowMenu, service, setService }}>
      {children}
    </ServiceContext.Provider>
  );
};

export const useService = () => useContext(ServiceContext);