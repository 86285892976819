import jwtSign from "jwt-encode";
export const userHasPermission = (user, permission, permissionsField = 'permissions') => {
  if(!user || !user[permissionsField] || !user[permissionsField].includes(permission)) return false;
  return true;
};
//?NOTE: THIS IS A TEMPORARY SOLUTION UNTIL BACKEND MIDDLEWARES ARE IMPLEMENTED
export const tokensGenerator = async () => {
  try {
    let signInObject = { username: "token", password: "token" };
    const currentTime = Math.floor(Date.now() / 1000);
    const expirationTime = currentTime + 4 * 60 * 60;
    signInObject.exp = expirationTime;

    const accessTokenDipsatcher = jwtSign(
      signInObject,
      process.env.REACT_APP_AUTH_GEN_KEY_Dispatcher,
      { expiresIn: expirationTime }
    );
    const accessTokenExtractor = jwtSign(
      signInObject,
      process.env.REACT_APP_AUTH_GEN_KEY_Extractor,
      { expiresIn: expirationTime }
    );
    const accessTokenDecision = jwtSign(
      signInObject,
      process.env.REACT_APP_AUTH_GEN_KEY_DECISION,
      { expiresIn: expirationTime }
    );
    const accessTokenSapient = jwtSign(
      signInObject,
      process.env.REACT_APP_AUTH_GEN_KEY_SAPIENT,
      { expiresIn: expirationTime }
    );
    return {
      accessTokenDipsatcher,
      accessTokenExtractor,
      accessTokenDecision,
      accessTokenSapient
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
};