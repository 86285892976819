import { useState, useEffect } from 'react';

const quotes = [
  "Every detail matters, every signal is caught—Minotaur is on watch.",
  "Uncover the hidden paths of your systems—trust the Minotaur.",
  "Keep your systems in the bull's-eye—Minotaur never misses a beat.",
  "Turn the beast of complexity into the beauty of simplicity—Minotaur’s magic."
];

const useQuoteSlider = () => {
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);
  const [progress, setProgress] = useState(Array(quotes.length).fill(0));
  const [autoSlide, setAutoSlide] = useState(true);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    let interval;
    if (autoSlide && !isPaused) {
      interval = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = [...prevProgress];
          newProgress[currentQuoteIndex] = Math.min(newProgress[currentQuoteIndex] + 2, 100);
          return newProgress;
        });

        if (progress[currentQuoteIndex] >= 100) {
          setCurrentQuoteIndex((prevIndex) => (prevIndex + 1) % quotes.length);
          setProgress(Array(quotes.length).fill(0));
        }
      }, 100);
    }

    return () => clearInterval(interval);
  }, [autoSlide, currentQuoteIndex, progress, isPaused]);

  const handleQuoteChange = (index) => {
    setAutoSlide(false);
    setIsPaused(true);
    setCurrentQuoteIndex(index);
    setProgress(Array(quotes.length).fill(0));

    setTimeout(() => {
      setIsPaused(false);
      setAutoSlide(true);
    }, 5000);
  };

  return {
    quotes,
    currentQuoteIndex,
    progress,
    handleQuoteChange,
  };
};

export default useQuoteSlider;