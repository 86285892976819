import { useEffect } from 'react';

const useHideLoader = () => {
  useEffect(() => {
    const MINIMUM_LOADING_TIME = 2000; // Minimum display time for the loader (2 seconds)
    const startTime = Date.now();

    const hideAppLoader = () => {
      const elapsedTime = Date.now() - startTime;
      const delay = Math.max(0, MINIMUM_LOADING_TIME - elapsedTime);

      setTimeout(() => {
        document.getElementById('avxInitLoader').classList.add('hiddenLoader');
      }, delay);
    };

    if (document.readyState === 'complete') {
      hideAppLoader();
    } else {
      window.addEventListener('load', hideAppLoader);
      return () => window.removeEventListener('load', hideAppLoader);
    }
  }, []);
};

export default useHideLoader;
