import { useState } from 'react';
import Cookies from 'js-cookie';

const useCookie = (key, defaultValue = null) => {
  const [cookieValue, setCookieValue] = useState(() => {
    try {
      const value = Cookies.get(key);
      return value || defaultValue;
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue = (newValue) => {
    try {
      if (newValue) Cookies.set(key, newValue, { secure: true, sameSite: 'Strict' });
      else Cookies.remove(key);
    } catch (err) { }
    setCookieValue(newValue);
  };

  return [cookieValue, setValue];
};

export default useCookie;
