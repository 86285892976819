import { IconMoon, IconSun } from '../_Catalog/icons';
const ThemeButton = ({
dark,
setDark,
  }) => {
    return (
        <label className="relative flex items-center cursor-pointer">
        <input
            type="checkbox"
            className="sr-only peer"
            checked={dark}
            onChange={() => setDark(!dark)}
        />

        <div className="w-12 h-7 rounded-full bg-gradient-to-r from-gray-400/15 to-gray-500/15
peer-checked:from-blue-900/20 peer-checked:to-indigo-700/20
transition-colors duration-300 ease-in-out">

            <div className={`absolute top-[4px] left-[4px] ${dark ? 'opacity-0' : 'opacity-100'} transition-opacity duration-300`}>
                <IconSun color="#ff5400" />
            </div>

            <div className={`absolute top-[4px] right-[3px] ${dark ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}>
                <IconMoon color="#FFFFFF" />
            </div>

            <div
                className={`absolute top-[2px] left-[2px] h-6 w-6 rounded-full drop-shadow-md shadow-slate-950 bg-white/50
flex items-center justify-center transition-all duration-300
${dark ? 'translate-x-[20px] bg-white/20' : ''}`}
            >
            </div>
        </div>
    </label>

    );
  };
  
  export default ThemeButton;
  