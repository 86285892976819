import jwtSign from "jwt-encode";
import Cookies from "js-cookie";
import httpCore from "../../../core/utils/apis/httpCore";

const signIn = async (credentials) => {
  try {
    let signInObject = { username: "token", password: "token" };
    const currentTime = Math.floor(Date.now() / 1000);
    const expirationTime = currentTime + 30 * 24 * 60 * 60;
    signInObject.exp = expirationTime;

    const accessTokenDipsatcher = jwtSign(
      signInObject,
      process.env.REACT_APP_AUTH_GEN_KEY_Dispatcher,
      { expiresIn: expirationTime }
    );
    const accessTokenExtractor = jwtSign(
      signInObject,
      process.env.REACT_APP_AUTH_GEN_KEY_Extractor,
      { expiresIn: expirationTime }
    );
    const accessTokenDecision = jwtSign(
      signInObject,
      process.env.REACT_APP_AUTH_GEN_KEY_DECISION,
      { expiresIn: expirationTime }
    );
    const accessTokenSapient = jwtSign(
      signInObject,
      process.env.REACT_APP_AUTH_GEN_KEY_SAPIENT,
      { expiresIn: expirationTime }
    );

    const loginToken = jwtSign(
      credentials,
      process.env.REACT_APP_AUTH_GEN_KEY,
      { expiresIn: "2m" }
    );
    const response = await httpCore.post("/public/mca-signin", {
      auth: loginToken,
    });
    if (response.data) {
      response.data = {
        ...response.data,
        accessTokenDipsatcher,
        accessTokenExtractor,
        accessTokenDecision,
        accessTokenSapient,
      };
    }
    // Set cookies (simulate HTTP-only by not exposing to JavaScript)
    Cookies.set("customerId", process.env.REACT_APP_CUSTOMER_ID, {
      secure: true,
      sameSite: "Strict",
    });

    return response;
  } catch (e) {
    throw e;
  }
};

const loginService = {
  signIn,
};

export default loginService;
