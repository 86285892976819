import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import reportWebVitals from './core/reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from "react-error-boundary";
import { AppGlobalProvider } from './core/contexts/AppGlobalProvider';
import { ServiceProvider } from './components/ServiceMenu/ServiceContext';

import AppAuthProvider from './core/contexts/AppAuthProvider';
import AppLoader from './components/AppLoader/AppLoader';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import Page404 from './pages/404/Page404';
import Page403 from './pages/403/Page403';
import LoginPage from './pages/LoginPage/LoginPage';
import UseLogout from './core/hooks/UseLogout';
import AppLayout from './components/AppLayout/AppLayout';
import useHideLoader from './core/hooks/useHideLoader';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/mainStylesheet.css';

const queryClient = new QueryClient();

const Home = React.lazy(() => import('./pages/Home/Home'));
const Systems = React.lazy(() => import('./pages/Systems/Systems'));
const AddSystem = React.lazy(() => import('./pages/Systems/AddSystem/AddSystem'));
const CreateTask = React.lazy(() => import('./pages/Transactions/CreateTask/CreateTask'));
const SystemDetails = React.lazy(() => import('./pages/Systems/SystemDetails/SystemDetails'));
const UpdateSystem = React.lazy(() => import('./pages/Systems/UpdateSystem/UpdateSystem'));
const UpdateOSSystem = React.lazy(() => import('./pages/Systems/UpdateOSSystem/UpdateOSSystem'));
const UpdateBoomiSystem = React.lazy(() => import('./pages/Systems/UpdateBoomiSystem/UpdateBoomiSystem'));
const Transactions = React.lazy(() => import('./pages/Transactions/Transactions'));
const UpdateTransaction = React.lazy(() => import('./pages/Transactions/UpdateTransaction/UpdateTransaction'));
const TransactionDetails = React.lazy(() => import('./pages/Transactions/TransactionDetails/TransactionDetails'));
const TransactionRule = React.lazy(() => import('./pages/Transactions/TransactionRule/TransactionRule'));
const General = React.lazy(() => import('./pages/Settings/General/General'));
const Sapient = React.lazy(() => import('./pages/Settings/Sapient/SapientConfig'));
const Alert = React.lazy(() => import('./pages/Alert/Alert'));
const DailyReport = React.lazy(() => import('./pages/DailyReport/DailyReport'));
const GroupManagement = React.lazy(() => import('./pages/Settings/GroupManagement/GroupManagement'));
const Jira = React.lazy(() => import('./pages/Settings/Jira/Jira'));
const ServiceNow = React.lazy(() => import('./pages/Settings/ServiceNow/ServiceNow'));
const Reporting = React.lazy(() => import('./pages/Reporting/Reporting'));
const JiraTickets = React.lazy(() => import('./pages/Tickets/Jira/Tickets'));
const ServiceNowTickets = React.lazy(() => import('./pages/Tickets/ServiceNow/ServiceNow'));
const OperationFlow = React.lazy(() => import('./pages/OperationFlow/OperationFlow'));
const FirstFlow = React.lazy(() => import('./pages/OperationFlow/Components/FirstFlow/FirstFlow'));
const SecondFlow = React.lazy(() => import('./pages/OperationFlow/Components/SecondFlow/SecondFlow'));
const AddOperationFlow = React.lazy(() => import('./pages/OperationFlow/AddOperationFlow/AddOperationFlow'));
const OperationFlowDetail = React.lazy(() => import('./pages/OperationFlow/OperationFlowDetail/OperationFlowDetail'));
const OperationFlowDetail2 = React.lazy(() => import('./pages/OperationFlow/OperationFlowDetail2/OperationFlowDetail2'));
const CortexReporting = React.lazy(() => import('./pages/CortexReporting/CortexReporting'));
const Deployment = React.lazy(() => import('./pages/Deployment/Deployment'));
const Dashboard = React.lazy(() => import('./pages/Dashboard/Dashboard'));
const PriorityManagement = React.lazy(() => import('./pages/Settings/PriorityManagement/PriorityManagement'));
const TaskManagement = React.lazy(() => import('./pages/Settings/TaskManagement/TaskManagement'));
const TierManagement = React.lazy(() => import('./pages/Settings/TierManagement/TierManagement'));
const AssignmentConfig = React.lazy(() => import('./pages/Settings/AssignmentConfig/AssignmentConfig'));
const Teams = React.lazy(() => import('./pages/Alert/Teams/TeamsAlert'));
const Sms = React.lazy(() => import('./pages/Alert/Sms/SmsAlert'));
const Email = React.lazy(() => import('./pages/Alert/Email/EmailAlert'));
const Workspace = React.lazy(() => import('./pages/Workspace/Workspace'));

const MainApp = () => {
  useHideLoader();

  return (
    <AppLayout>
      <ErrorBoundary fallback={<div>Something went wrong</div>}>
        <Routes>
          {/* Private Routes With Restricted Access -> Require Permission */}
          <Route path="" element={<ProtectedRoute><Suspense fallback={<AppLoader />}><Workspace /></Suspense></ProtectedRoute>} />
          <Route path="/dashboard" element={<ProtectedRoute><Suspense fallback={<AppLoader />}><Home /></Suspense></ProtectedRoute>} />
          <Route path="/incident" element={<ProtectedRoute />}>
            <Route path="dashboard" element={<Suspense fallback={<AppLoader />}><Dashboard /></Suspense>} />
            <Route path="priority-management" element={<Suspense fallback={<AppLoader />}><PriorityManagement /></Suspense>} />
            <Route path="task-management" element={<Suspense fallback={<AppLoader />}><TaskManagement /></Suspense>} />
            <Route path="tier-management" element={<Suspense fallback={<AppLoader />}><TierManagement /></Suspense>} />
            <Route path="assignment-config" element={<Suspense fallback={<AppLoader />}><AssignmentConfig /></Suspense>} />
            <Route path="teams" element={<Suspense fallback={<AppLoader />}><Teams /></Suspense>} />
            <Route path="sms" element={<Suspense fallback={<AppLoader />}><Sms /></Suspense>} />
            <Route path="email" element={<Suspense fallback={<AppLoader />}><Email /></Suspense>} />
            <Route path="service-now" element={<Suspense fallback={<AppLoader />}><ServiceNow /></Suspense>} />
          </Route>

          <Route path="/settings" element={<ProtectedRoute />}>
            <Route path="general" element={<Suspense fallback={<AppLoader />}><General /></Suspense>} />
            <Route path="sapient" element={<Suspense fallback={<AppLoader />}><Sapient /></Suspense>} />
            <Route path="dailyReport" element={<Suspense fallback={<AppLoader />}><DailyReport /></Suspense>} />
            <Route path="notif" element={<ProtectedRoute />}>
              <Route path="Alert" element={<Suspense fallback={<AppLoader />}><Alert /></Suspense>} />
              <Route path="groupmanagement" element={<Suspense fallback={<AppLoader />}><GroupManagement /></Suspense>} />
              <Route path="jira" element={<Suspense fallback={<AppLoader />}><Jira /></Suspense>} />
            </Route>
          </Route>

          <Route path="cortexFlow/settings" element={<ProtectedRoute />}>
            <Route path="general" element={<Suspense fallback={<AppLoader />}><General /></Suspense>} />
            <Route path="sapient" element={<Suspense fallback={<AppLoader />}><Sapient /></Suspense>} />
          </Route>

          <Route path="/schedules" element={<ProtectedRoute />}>
            <Route index element={<Suspense fallback={<AppLoader />}><Reporting /></Suspense>} />
          </Route>

          <Route path="/connections" element={<ProtectedRoute />}>
            <Route index element={<Suspense fallback={<AppLoader />}><Systems /></Suspense>} />
            <Route path="create-connection" element={<Suspense fallback={<AppLoader />}><AddSystem /></Suspense>} />
            <Route path="connections-details/:id" element={<Suspense fallback={<AppLoader />}><SystemDetails /></Suspense>} />
            <Route path="update-connections/:id" element={<Suspense fallback={<AppLoader />}><UpdateSystem /></Suspense>} />
            <Route path="update-os-connections/:id" element={<Suspense fallback={<AppLoader />}><UpdateOSSystem /></Suspense>} />
            <Route path="update-boomi-connections/:id" element={<Suspense fallback={<AppLoader />}><UpdateBoomiSystem /></Suspense>} />
          </Route>

          <Route path="/cortexFlow/connections" element={<ProtectedRoute />}>
            <Route index element={<Suspense fallback={<AppLoader />}><Systems /></Suspense>} />
            <Route path="create-connection" element={<Suspense fallback={<AppLoader />}><AddSystem /></Suspense>} />
            <Route path="connections-details/:id" element={<Suspense fallback={<AppLoader />}><SystemDetails /></Suspense>} />
            <Route path="update-connections/:id" element={<Suspense fallback={<AppLoader />}><UpdateSystem /></Suspense>} />
          </Route>

          <Route path="/reporting" element={<ProtectedRoute />}>
            <Route index element={<Suspense fallback={<AppLoader />}><Reporting /></Suspense>} />
          </Route>

          <Route path="/tasks" element={<ProtectedRoute />}>
            <Route index element={<Suspense fallback={<AppLoader />}><Transactions /></Suspense>} />
            <Route path="create-task" element={<Suspense fallback={<AppLoader />}><CreateTask /></Suspense>} />
            <Route path="task-details/:id" element={<Suspense fallback={<AppLoader />}><TransactionDetails /></Suspense>} />
            <Route path=":id/rule" element={<Suspense fallback={<AppLoader />}><TransactionRule /></Suspense>} />
            <Route path="update-task/:id" element={<Suspense fallback={<AppLoader />}><UpdateTransaction /></Suspense>} />
          </Route>

          <Route path="/operation-flow" element={<ProtectedRoute />}>
            <Route index element={<Suspense fallback={<AppLoader />}><OperationFlow /></Suspense>} />
            <Route path="create-flow" element={<Suspense fallback={<AppLoader />}><AddOperationFlow /></Suspense>} />
            <Route path="operation-flow-details/:workflowId" element={<Suspense fallback={<AppLoader />}><OperationFlowDetail /></Suspense>} />
            <Route path="operation-flow-details/1" element={<Suspense fallback={<AppLoader />}><FirstFlow /></Suspense>} />
            <Route path="operation-flow-details/2" element={<Suspense fallback={<AppLoader />}><SecondFlow /></Suspense>} />
            <Route path="operation-flow-details/4" element={<Suspense fallback={<AppLoader />}><OperationFlowDetail2 /></Suspense>} />
          </Route>

          <Route path="/tickets" element={<ProtectedRoute />}>
            <Route path="jira" element={<Suspense fallback={<AppLoader />}><JiraTickets /></Suspense>} />
            <Route path="service-now" element={<Suspense fallback={<AppLoader />}><ServiceNowTickets /></Suspense>} />
          </Route>

          <Route path="/cortex" element={<ProtectedRoute />}>
            <Route path="reporting" element={<Suspense fallback={<AppLoader />}><CortexReporting /></Suspense>} />
            <Route path="deployment" element={<Suspense fallback={<AppLoader />}><Deployment /></Suspense>} />
          </Route>

          {/* Public Routes -> No Auth Required */}
          <Route path='/login' element={<LoginPage />} />
          <Route path='/logout' element={<UseLogout />} />
          <Route path='/403' element={<Page403 />} />
          <Route path='*' element={<Page404 />} />
        </Routes>
      </ErrorBoundary>
    </AppLayout>
  );
};

const root = ReactDOM.createRoot(document.getElementById('mainApp'));
root.render(
  // <React.StrictMode>
  <BrowserRouter basename="/">
    <AppAuthProvider>
      <AppGlobalProvider>
        <ServiceProvider>
          <QueryClientProvider client={queryClient}>
            <MainApp />
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
          </QueryClientProvider>
        </ServiceProvider>
      </AppGlobalProvider>
    </AppAuthProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

reportWebVitals();
