import { NavLink } from "react-router-dom";

const NavigationLink = ({ children, name, path, onClick }) => {
  const handleClick = () => {
    if (onClick) {
      onClick(); 
    }
  };

  return (
    <NavLink
      to={path}
      onClick={handleClick}
      className={({ isActive }) =>
        `flex items-center py-1 pl-2 rounded place-items-center gap-2 transition-colors duration-100 ${
          isActive ? "bg-white text-primary-color" : "hover:bg-primary-bg-color text-primary-text-color"
        }`
      }
    >
      {children}
      <p className="text-[15px] font-medium overflow-clip whitespace-nowrap tracking-wide">
        {name}
      </p>
    </NavLink>
  );
};

export default NavigationLink;
